import React, { use, useState } from 'react';
import { Form } from '@unform/web';
import { Container } from './styles';
import { Dialog, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { NovoUsuarioService } from '@/services/endpoints/novo_usuario';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PasswordModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { changePassword } = new NovoUsuarioService();
  const { enqueueSnackbar } = useSnackbar();

  const [current,setCurrent] = useState<any>()
  const [confirmation,setConfirmation] = useState<any>()
  const [newPass,setNewPass] = useState<any>()

  const handleSubmit = (data: any) => {
    if (!current) {
      enqueueSnackbar('Senha atual é obrigatória.', { variant: 'error' });
    }

    if (!newPass) {
      enqueueSnackbar('Nova senha é obrigatória.', { variant: 'error' });
    }

    if (!confirmation) {
      enqueueSnackbar('Confirmação de senha obrigatória.', { variant: 'error' });
    }

    if (newPass !== confirmation) {
      enqueueSnackbar('Senhas não são correspondentes.', { variant: 'error' });
      return;
    }
    
    const userId = JSON.parse(localStorage.getItem("USER_LOG_ID") as string);

    changePassword({current_password:current,new_password:newPass,new_password_confirmation:confirmation}, userId).then(() => {
      enqueueSnackbar('Senha alterada com sucesso.', { variant: 'success' });
      onClose();
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Container>
        <h1>Nova senha</h1>
        <Form onSubmit={() => console.log("")}>
          <TextField onChange={(e) => setCurrent(e.target.value)} id="outlined-basic" sx={{width:"100%"}}  name='current_password' label="Senha Atual" variant="outlined" />
          <TextField onChange={(e) => setNewPass(e.target.value)} id="outlined-basic" sx={{width:"100%", marginTop:"5%"}}  name='new_password' label="Nova senha" variant="outlined" />
          <TextField onChange={(e) => setConfirmation(e.target.value)} id="outlined-basic" sx={{width:"100%", marginTop:"5%"}}  name='new_password_confirmation' label="Confirme" variant="outlined" />
          
          <div className='button_wrapper'>
            <button className='cancel_button' onClick={onClose}>
              <p>Cancelar</p>
            </button>
            <button onClick={handleSubmit} className='confirm_button'>
              <p>Confirmar</p>
            </button>
          </div>
        </Form>
      </Container>
    </Dialog>
  );
};