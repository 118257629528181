import styled, { css } from "styled-components";

interface IProps {
  open: boolean;
  mobile: boolean;
}

export const Container = styled.div<IProps>`
  /*z-index: 99999;*/
  width: 20%;
  color: white;
  display: ${({ open }) => open ? 'flex' : 'none'};
  background: #514F4E;
  align-items: center;
  flex-direction: column;
  box-shadow: 5px 0px 13px 0px rgba(0, 0, 0, 0.21);

  ${({ mobile, open }) => mobile && open && css`
    position: absolute;
    height: 100vh;
  `};

  @media(min-width: 500px) and (max-width: 960px) {
    width: 40%;
  }

  @media(max-width: 500px) {
    width: 60%;
  }
  
  .content_wrapper {
    width: 80%;
  }

  .close_wrapper {
    width: 100%;
    display: flex;
    margin: 20px 20px 0 0;
    justify-content: end;
  }

  .fill {
    width: 100%;
  }
`;

export const Header = styled.header`
  gap: 70px;
  display: flex;
  flex-direction: column;
  
  .icone {
    width: 20px;
    height: 20px;
    background: red;
  }
`;

export const Nav = styled.nav`
  gap: 28px;
  display: flex;
  margin-top: 70px;
  flex-direction: column;
  
  .item_wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    
    .icone {
      width: 24px;
      background: red;
      height: 24px;
    }
  }

  .sub_item_wrapper {
    .content {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 11px;
    }

    display: flex;
    flex-direction: column;
    cursor: pointer;
    
    .icone {
      width: 24px;
      background: red;
      height: 24px;
    }
    .divider {
      height: 2px;
      width: 100%;
      background: #FFF;
    }

    .sub_item {
      display: flex;
      p { margin-left: 2.2rem; }
      margin-top: 11px;
    }
  }

`;

export const Exit = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  
  .icone {
      width: 24px;
      background: red;
      height: 24px;
    }

  .item_wrapper {
    display: flex;
    gap: 10px;
  }
`;

export const CircleFluid = styled.div`
  top: 60px;
  gap: 7.61px;

  padding: 8px;
  right: -15px;

  display: flex;
  cursor: pointer;
  
  z-index: 999999;
  position: absolute;
  
  align-items: center;
  border-radius: 50%;
  
  background-color: white;
  justify-content: center;
  
  box-shadow: 0px 4px 4px 0px #00000040;
  
  p {
  color: #004A80;
  font-size: 19px;
  
  text-align: left;
  font-weight: 700;
  
  line-height: 25px;
  letter-spacing: 0em;
}
`;