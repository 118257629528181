export const light = {
  header: {
    navigation_span: "#FFFFFF",
    header_background: "#004F9F",
    logo_header_background: "#FFFFFF",
  },
  home: {
    banner: {
      banner_background: "#FFF",
      accession_button: "#009E3D",
      color: "#000",
    },
    objectives: {
      objectives_background: "#F4F4F4",
      description: "#1D1D1B",
      text_content: "#000",
    },
    action_area: {
      action_area_background: "#FFF",
      title_area: "#000",
    },
    approuch: {
      background: "#F4F4F4",
      text_content: "#000",
      new_approuch: "#004F9F",
    },
  },
  participe: {
    banner: {
      background: "#FFF",
      text: "#000",
    },
    form: {
      background: "#FFF",
    },
  },
  perguntas: {
    accordeon: {
      background: "#FFF",
    },
  },
  acontece: {
    load_more: {
      main_color: "#004F9F",
      icon_load: "#004F9F",
    },
    card: {
      main_color: "#004F9F",
    },
  },
  acontece_interno: {
    background: "#FFF",
    secundary_text: "#000",
    primary_text: "#205ca8",
  },
  login:{
    button:{
      bg: '#205CA8',
    }
  }
};
