import React from 'react'

export default function HelpDesk() {
  return (
    <svg id="interrogation" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <path  fill="white" id="Caminho_8" data-name="Caminho 8" d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18.333A8.333,8.333,0,1,1,18.333,10,8.333,8.333,0,0,1,10,18.333Z" transform="translate(0 0)"/>
  <path  fill="white" id="Caminho_9" data-name="Caminho 9" d="M12.078,5.054A3.458,3.458,0,0,0,8,8.458a.864.864,0,1,0,1.729,0,1.729,1.729,0,0,1,2.05-1.7,1.75,1.75,0,0,1,1.383,1.379A1.729,1.729,0,0,1,12.3,9.973,3.418,3.418,0,0,0,10.593,13v.642a.864.864,0,1,0,1.729,0V13a1.713,1.713,0,0,1,.8-1.515,3.458,3.458,0,0,0-1.049-6.434Z" transform="translate(-1.458 -0.965)"/>
  <rect fill="white"  id="Retângulo_20" data-name="Retângulo 20" width="2.053" height="1.642" rx="0.821" transform="translate(8.995 14.019)"/>
</svg>

  )
}
