import { useTheme } from "@/services/zustand/theme";
import { useRouter } from "next/router";
import styled from 'styled-components'

const Container = styled.svg`

.cls-1 {
        fill: #fff;
        stroke-width: 0px;
      }
`

export const MunicipalismoIcon = () => {
  const theme = useTheme(state => state.theme);
  const router = useRouter();
  return (
    <Container id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 735  254.98">
    <defs>
     
    </defs>
    <g>
      <g>
        <path className="cls-1" d="m161.97,83.13c-.9,1.92-2.82,3.15-4.95,3.15h-5.73l3.13-6.71c.71-1.52,2.24-2.49,3.91-2.49h6.45l-2.82,6.06Z"/>
        <path className="cls-1" d="m200.72,86.28h-7.45l3.86-8.27c.63-1.35,1.98-2.21,3.46-2.21h8.04l-3.61,7.74c-.78,1.67-2.46,2.74-4.3,2.74Z"/>
        <path className="cls-1" d="m243.78,86.28h-9.22l5.08-10.9c.67-1.44,2.12-2.37,3.72-2.37h10.66l-4.48,9.6c-1.04,2.24-3.29,3.67-5.76,3.67Z"/>
        <path className="cls-1" d="m291.7,86.28h-12.81l6.05-12.98c.83-1.77,2.61-2.91,4.57-2.91h12.68l-6.11,13.1c-.8,1.71-2.51,2.8-4.39,2.8Z"/>
        <path className="cls-1" d="m322.69,86.28h11.4c2.74,0,5.24-1.59,6.4-4.08l5.51-11.82h-11.35c-2.77,0-5.29,1.61-6.46,4.12l-5.49,11.78Z"/>
        <path className="cls-1" d="m320.22,65.14h-12.31l5.72-12.27c1.03-2.21,3.25-3.62,5.68-3.62h11.9l-5.9,12.65c-.92,1.98-2.91,3.25-5.1,3.25Z"/>
        <path className="cls-1" d="m299.25,43.56h-10.46l4.85-10.4c.82-1.75,2.57-2.87,4.51-2.87h10.1l-5,10.72c-.72,1.55-2.28,2.55-4,2.55Z"/>
        <path className="cls-1" d="m277.92,22.08h-8.11l3.66-7.85c.75-1.6,2.35-2.63,4.12-2.63h7.58l-3.89,8.33c-.61,1.31-1.92,2.14-3.37,2.14Z"/>
      </g>
      <g>
        <path className="cls-1" d="m89.46,179.33l6.25-47.39c1.17-8.33,3.12-21.22,4.69-29.68h-.39c-4.04,8.85-8.98,19.27-13.67,28.25l-24.59,45.39c-.97,1.79-2.85,2.91-4.88,2.91h-4.89l-6.51-47.26c-1.3-10.15-2.21-20.31-2.73-29.29h-.39c-1.82,8.07-4.56,21.35-6.64,29.68l-10.96,43.36c-.6,2.37-2.73,4.03-5.17,4.03h-6l22.78-87.74h13.8l6.9,50.25c.91,7.55,1.82,15.49,2.08,22h.39c2.73-6.51,6.51-14.32,10.28-21.61l27.08-50.64h14.19l-10.71,83.9c-.28,2.2-2.15,3.84-4.37,3.84h-6.54Z"/>
        <path className="cls-1" d="m95.99,179.7h-6.96l6.3-47.8c1.19-8.46,2.98-20.16,4.42-28.21-3.38,7.37-8.22,17.65-13.1,26.99l-24.59,45.39c-1.04,1.91-3.03,3.1-5.21,3.1h-5.22l-.04-.32-6.51-47.26c-1.18-9.22-2.1-18.89-2.66-28.08-.56,2.5-1.19,5.4-1.85,8.45-1.48,6.82-3.15,14.55-4.53,20.06l-10.96,43.36c-.64,2.53-2.91,4.31-5.53,4.31h-6.48l.12-.46,22.85-88.02h14.41l.04.32,6.9,50.25c.87,7.24,1.75,14.81,2.05,21.23,2.5-5.87,6.03-13.28,10.01-20.96l27.18-50.84h14.83l-10.76,84.31c-.3,2.38-2.33,4.17-4.73,4.17Zm-6.12-.74h6.12c2.02,0,3.75-1.51,4-3.52l10.65-83.48h-13.55l-26.97,50.44c-4.12,7.96-7.77,15.63-10.27,21.58l-.1.23h-.99v-.36c-.27-6.56-1.18-14.45-2.09-21.97l-6.86-49.92h-13.19l-22.59,87h5.52c2.28,0,4.26-1.54,4.81-3.75l10.96-43.36c1.37-5.49,3.05-13.22,4.53-20.03.77-3.55,1.49-6.89,2.11-9.64l.06-.29h1.04l.02.35c.56,9.53,1.5,19.65,2.73,29.26l6.47,46.93h4.57c1.9,0,3.65-1.04,4.56-2.71l24.59-45.39c5.15-9.88,10.27-20.8,13.66-28.23l.1-.22h1.07l-.08.44c-1.48,8.02-3.42,20.68-4.68,29.66l-6.19,46.96Z"/>
      </g>
      <g>
        <path className="cls-1" d="m158.84,179.33c.53-4.36,1.56-9.77,2.34-16.28l-1.55,3.61c-6.77,13.15-15.11,14.11-24.1,14.11-11.19,0-18.1-8.85-18.1-20.83,0-22,16.14-45.04,43.61-45.04,6.12,0,12.63,1.04,17.05,2.34,0,0-6.64,43.69-8.62,58.22-.3,2.23-2.2,3.88-4.45,3.88h-6.2Zm8.17-54.8c-1.43-.52-4.91-1.04-8.56-1.04-16.4,0-29.81,16.92-29.81,34.5,0,7.03,2.47,13.93,10.8,13.93,8.98,0,20.8-11.58,23.92-28.38l3.65-19.01Z"/>
        <path className="cls-1" d="m135.54,181.13c-11.04,0-18.47-8.52-18.47-21.2,0-22.32,16.45-45.41,43.98-45.41,5.64,0,12.22.91,17.16,2.36l.31.09-.05.32c-.07.44-6.67,43.85-8.62,58.21-.32,2.39-2.39,4.2-4.81,4.2h-6.61l.05-.41c.24-1.95.58-4.16.94-6.49.34-2.18.71-4.59,1.06-7.17l-.51,1.18c-6.82,13.25-15.1,14.33-24.44,14.33Zm25.52-65.87c-27.07,0-43.24,22.71-43.24,44.67,0,12.24,7.12,20.46,17.72,20.46,9.09,0,17.15-1.04,23.77-13.9l2.55-5.95-.31,2.55c-.44,3.61-.95,6.9-1.4,9.81-.34,2.17-.66,4.22-.89,6.05h5.78c2.05,0,3.81-1.53,4.08-3.56,1.87-13.71,7.96-53.89,8.57-57.9-4.84-1.38-11.18-2.24-16.64-2.24Zm-21.61,57.02c-9.72,0-11.18-8.96-11.18-14.3,0-18.57,14.11-34.87,30.18-34.87,3.74,0,7.26.55,8.69,1.07l.29.11-.06.31-3.64,19c-3.19,17.15-15.13,28.68-24.28,28.68Zm19.01-48.43c-15.69,0-29.44,15.95-29.44,34.13,0,5.06,1.36,13.56,10.44,13.56s20.48-11.53,23.56-28.07l3.58-18.69c-1.52-.46-4.72-.92-8.13-.92Z"/>
      </g>
      <g>
        <path className="cls-1" d="m228.35,176.72c-3.26,1.69-9.76,3.91-18.23,3.91-16.27,0-24.21-10.94-24.21-24.34,0-21.48,15.88-41.27,38.53-41.27,5.86,0,11.2,1.69,13.15,2.73l-3.12,8.59c-2.47-1.17-5.99-2.34-10.68-2.34-16.4,0-26.69,16.14-26.69,30.98,0,10.15,5.34,16.79,15.62,16.79,6.51,0,11.33-1.95,14.58-3.51l1.04,8.46Z"/>
        <path className="cls-1" d="m210.13,181c-14.93,0-24.58-9.7-24.58-24.71,0-22.96,17.45-41.64,38.9-41.64,5.91,0,11.31,1.7,13.32,2.78l.28.15-3.37,9.28-.37-.17c-3.28-1.56-6.72-2.31-10.52-2.31-16.26,0-26.32,15.89-26.32,30.61,0,10.44,5.56,16.42,15.25,16.42,6.35,0,11.04-1.86,14.42-3.48l.46-.22,1.13,9.23-.23.12c-1.78.93-8.37,3.95-18.4,3.95Zm14.32-65.61c-21.04,0-38.16,18.35-38.16,40.9,0,11.59,6.26,23.97,23.84,23.97,9.47,0,15.8-2.73,17.83-3.75l-.95-7.7c-3.41,1.6-8.06,3.33-14.27,3.33-10.01,0-15.99-6.42-15.99-17.17,0-15.08,10.35-31.35,27.06-31.35,3.72,0,7.24.74,10.47,2.21l2.87-7.91c-2.14-1.04-7.18-2.54-12.69-2.54Z"/>
      </g>
      <g>
        <path className="cls-1" d="m240.2,179.33l7.68-40.62c1.43-8.2,2.47-16.53,3.12-22.39h9.5c-.52,4.43-1.04,8.85-1.69,13.67h.39c4.03-8.59,10.8-15.1,19.27-15.1,1.04,0,2.21.13,3.12.26l-2.08,10.67c-.78-.13-1.82-.26-2.99-.26-9.37,0-17.71,11.33-20.44,26.04l-4.42,23.52c-.46,2.44-2.59,4.2-5.07,4.2h-6.4Z"/>
        <path className="cls-1" d="m246.59,179.7h-6.84l.08-.44,7.68-40.62c1.45-8.29,2.5-16.74,3.12-22.36l.04-.33h10.25l-.05.41c-.44,3.77-.94,8-1.54,12.51,4.58-9.14,11.51-14.35,19.13-14.35,1.1,0,2.33.14,3.18.26l.39.06-2.23,11.41-.35-.06c-1.03-.17-1.99-.26-2.93-.26-8.86,0-17.3,10.82-20.08,25.73l-4.42,23.53c-.49,2.61-2.77,4.51-5.43,4.51Zm-5.95-.74h5.95c2.3,0,4.28-1.64,4.7-3.9l4.42-23.53c2.84-15.26,11.59-26.34,20.8-26.34.87,0,1.76.07,2.7.21l1.94-9.94c-.78-.1-1.79-.2-2.69-.2-7.58,0-14.49,5.43-18.93,14.89l-.1.21h-1.05l.06-.42c.64-4.77,1.17-9.26,1.64-13.25h-8.76c-.63,5.63-1.67,13.93-3.09,22.08l-7.6,40.18Z"/>
      </g>
      <g>
        <path className="cls-1" d="m341.22,141.05c0,19.53-13.8,39.7-35.02,39.7-15.75,0-24.6-11.72-24.6-25.91,0-20.83,14.45-39.97,34.89-39.97,17.05,0,24.73,12.89,24.73,26.17Zm-48.43,13.41c0,10.28,5.73,17.7,14.84,17.7,12.76,0,22.39-16.4,22.39-31.11,0-7.29-3.38-17.57-14.71-17.57-13.54,0-22.52,16.53-22.52,30.98Z"/>
        <path className="cls-1" d="m306.2,181.13c-14.94,0-24.97-10.56-24.97-26.28,0-22.24,15.82-40.33,35.26-40.33,17.34,0,25.1,13.33,25.1,26.54,0,19.27-13.53,40.07-35.39,40.07Zm10.28-65.87c-19.03,0-34.52,17.76-34.52,39.59,0,12.71,7.49,25.54,24.23,25.54,21.4,0,34.65-20.42,34.65-39.33,0-12.84-7.53-25.8-24.36-25.8Zm-8.85,57.28c-9.1,0-15.21-7.26-15.21-18.08,0-14.83,9.4-31.35,22.89-31.35,11.13,0,15.08,9.67,15.08,17.95,0,14.86-9.73,31.48-22.76,31.48Zm7.68-48.69c-13.05,0-22.15,16.13-22.15,30.61,0,10.37,5.82,17.33,14.47,17.33,12.6,0,22.02-16.23,22.02-30.74,0-7.94-3.76-17.2-14.34-17.2Z"/>
      </g>
      <g>
        <path className="cls-1" d="m365.56,92.89c4.82-1.17,11.85-1.95,19.01-1.95,8.46,0,16.53,2.08,21.48,6.77,4.17,3.78,6.38,8.98,6.38,15.88,0,9.76-4.82,18.1-10.54,22.91-6.77,5.73-16.66,8.33-26.56,8.33-3.38,0-6.25-.26-8.98-.65l-5.94,31.47c-.4,2.13-2.27,3.68-4.44,3.68h-6.93l16.53-86.44Zm2.47,42.18c2.99.65,5.6.91,8.72.91,14.84,0,24.73-9.24,24.73-21.61,0-10.54-7.42-14.71-17.45-14.71-4.3,0-7.55.39-9.5.91l-6.51,34.5Z"/>
        <path className="cls-1" d="m355.95,179.7h-7.38l16.66-87.11.23-.06c5.05-1.23,12.19-1.96,19.09-1.96,9.34,0,17.06,2.44,21.74,6.87,4.31,3.9,6.49,9.34,6.49,16.15,0,8.9-3.99,17.57-10.68,23.2-6.42,5.43-15.93,8.42-26.79,8.42-2.92,0-5.69-.19-8.69-.61l-5.88,31.12c-.44,2.3-2.46,3.98-4.8,3.98Zm-6.48-.74h6.48c1.99,0,3.71-1.42,4.08-3.37l6.01-31.82.35.05c3.09.44,5.93.65,8.93.65,10.69,0,20.03-2.93,26.32-8.24,5.04-4.23,10.41-12.4,10.41-22.63,0-6.68-2.05-11.79-6.26-15.61-4.54-4.31-12.08-6.67-21.23-6.67-6.74,0-13.7.7-18.69,1.88l-16.4,85.77Zm27.28-42.61c-3.2,0-5.83-.28-8.8-.92l-.35-.08.07-.35,6.55-34.73.22-.06c2.24-.6,5.65-.93,9.6-.93,11.49,0,17.82,5.36,17.82,15.08,0,12.94-10.32,21.98-25.1,21.98Zm-8.29-1.57c2.78.58,5.28.83,8.29.83,14.34,0,24.36-8.73,24.36-21.24,0-11.85-9.28-14.34-17.07-14.34-3.7,0-7.02.31-9.18.84l-6.4,33.91Z"/>
      </g>
      <g>
        <path className="cls-1" d="m414.11,179.33l17.57-92.43h10.8l-16.66,87.65c-.53,2.77-2.95,4.78-5.78,4.78h-5.94Z"/>
        <path className="cls-1" d="m420.05,179.7h-6.39l.08-.44,17.63-92.73h11.56l-.08.44-16.66,87.65c-.56,2.94-3.14,5.08-6.14,5.08Zm-5.49-.74h5.49c2.64,0,4.92-1.88,5.41-4.48l16.58-87.2h-10.05l-17.43,91.68Z"/>
      </g>
      <g>
        <path className="cls-1" d="m511.87,179.33l8.33-44.65c1.43-7.03,2.34-13.15,3-18.36h9.63l-1.56,12.37h.26c5.6-8.85,14.19-13.8,23.3-13.8,7.55,0,16.01,4.3,16.01,17.44,0,2.86-.52,6.77-1.17,10.15l-6.25,32.78c-.45,2.36-2.51,4.07-4.91,4.07h-6.54l6.9-36.45c.39-2.47.78-5.34.78-8.07,0-6.25-2.47-10.94-9.89-10.94s-18.62,9.24-21.48,24.86l-4.96,26.5c-.44,2.37-2.52,4.1-4.93,4.1h-6.51Z"/>
        <path className="cls-1" d="m558.5,179.7h-6.98l.08-.44,6.9-36.45c.38-2.42.77-5.28.77-8,0-7.01-3.2-10.57-9.52-10.57-6.96,0-18.18,8.53-21.12,24.56l-4.96,26.5c-.48,2.55-2.71,4.4-5.3,4.4h-6.95l8.42-45.09c1.21-5.96,2.19-11.96,2.99-18.33l.04-.32h10.38l-1.48,11.68c5.62-8.35,13.97-13.11,23.06-13.11,7.92,0,16.38,4.68,16.38,17.81,0,2.61-.43,6.34-1.18,10.23l-6.25,32.78c-.48,2.53-2.7,4.36-5.28,4.36Zm-6.09-.74h6.09c2.22,0,4.13-1.58,4.55-3.76l6.25-32.78c.74-3.84,1.17-7.52,1.17-10.09,0-15.84-11.97-17.07-15.64-17.07-9.14,0-17.51,4.97-22.99,13.62l-.11.17h-.88l1.56-12.37h-8.89c-.79,6.28-1.76,12.19-2.96,18.06l-8.25,44.21h6.06c2.24,0,4.16-1.59,4.57-3.79l4.96-26.5c2.76-15.05,13.52-25.17,21.84-25.17,6.81,0,10.26,3.81,10.26,11.31,0,2.78-.4,5.67-.78,8.13l-6.82,36.02Z"/>
      </g>
      <g>
        <path className="cls-1" d="m485.58,179.33c.53-4.36,1.56-9.77,2.34-16.28l-1.55,3.61c-6.77,13.15-15.11,14.11-24.1,14.11-11.19,0-18.1-8.85-18.1-20.83,0-22,16.14-45.04,43.61-45.04,6.12,0,12.63,1.04,17.05,2.34,0,0-6.64,43.69-8.62,58.22-.3,2.23-2.2,3.88-4.45,3.88h-6.2Zm8.17-54.8c-1.43-.52-4.91-1.04-8.56-1.04-16.4,0-29.81,16.92-29.81,34.5,0,7.03,2.47,13.93,10.8,13.93,8.98,0,20.8-11.58,23.92-28.38l3.65-19.01Z"/>
        <path className="cls-1" d="m462.27,181.13c-11.04,0-18.47-8.52-18.47-21.2,0-22.32,16.45-45.41,43.98-45.41,5.64,0,12.22.91,17.16,2.36l.31.09-.05.32c-.07.44-6.67,43.85-8.62,58.21-.32,2.39-2.39,4.2-4.81,4.2h-6.61l.05-.41c.24-1.95.58-4.16.94-6.49.34-2.18.71-4.59,1.06-7.17l-.51,1.18c-6.82,13.25-15.1,14.33-24.44,14.33Zm25.52-65.87c-27.07,0-43.24,22.71-43.24,44.67,0,12.24,7.12,20.46,17.72,20.46,9.09,0,17.15-1.04,23.77-13.9l2.55-5.95-.31,2.55c-.44,3.61-.95,6.9-1.4,9.81-.34,2.17-.66,4.22-.89,6.05h5.78c2.05,0,3.81-1.53,4.08-3.56,1.87-13.71,7.96-53.89,8.57-57.9-4.84-1.38-11.18-2.24-16.64-2.24Zm-21.61,57.02c-9.72,0-11.18-8.96-11.18-14.3,0-18.57,14.11-34.87,30.18-34.87,3.74,0,7.26.55,8.69,1.07l.29.11-.06.31-3.64,19c-3.19,17.15-15.13,28.68-24.28,28.68Zm19.01-48.43c-15.69,0-29.44,15.95-29.44,34.13,0,5.06,1.36,13.56,10.44,13.56s20.48-11.53,23.56-28.07l3.58-18.69c-1.52-.46-4.72-.92-8.13-.92Z"/>
      </g>
    </g>
    <g>
      <g>
        <path className="cls-1" d="m110.19,236.95c-.93.49-2.39.8-4.25.8-5.85,0-10.01-4.7-10.01-14.84,0-11.78,5.85-15.55,10.54-15.55,1.82,0,3.1.35,3.72.75l-.75,2.88c-.71-.31-1.46-.62-3.01-.62-3.19,0-6.91,3.1-6.91,12.23s3.37,12.14,7,12.14c1.29,0,2.44-.31,3.15-.67l.53,2.88Z"/>
        <path className="cls-1" d="m105.94,237.9c-6.46,0-10.16-5.46-10.16-14.99,0-11.59,5.76-15.7,10.69-15.7,1.62,0,3.05.29,3.8.78l.09.06-.83,3.15-.16-.07c-.65-.29-1.39-.61-2.95-.61-3.12,0-6.76,3.16-6.76,12.08,0,10.43,4.29,11.99,6.85,11.99,1.11,0,2.27-.24,3.08-.65l.18-.09.59,3.18-.1.05c-1,.53-2.54.81-4.32.81Zm.53-30.39c-4.79,0-10.39,4.03-10.39,15.4,0,9.33,3.59,14.69,9.86,14.69,1.68,0,3.12-.26,4.08-.73l-.48-2.58c-.84.38-1.95.6-3.03.6-2.67,0-7.15-1.6-7.15-12.29,0-9.12,3.65-12.38,7.06-12.38,1.5,0,2.27.29,2.91.56l.68-2.6c-.73-.42-2.04-.67-3.55-.67Z"/>
      </g>
      <g>
        <path className="cls-1" d="m130.43,226.76c0,8.28-3.85,11.07-7.13,11.07-3.85,0-6.95-3.5-6.95-11.03s3.5-11.12,7.18-11.12,6.91,3.59,6.91,11.07Zm-10.67,0c0,3.54.62,8.37,3.72,8.37,2.92,0,3.5-5.05,3.5-8.37s-.58-8.33-3.59-8.33-3.63,5.05-3.63,8.33Z"/>
        <path className="cls-1" d="m123.3,237.99c-3.43,0-7.1-2.94-7.1-11.18s3.78-11.27,7.33-11.27,7.06,2.95,7.06,11.22-3.92,11.22-7.28,11.22Zm.22-22.15c-3.4,0-7.03,2.88-7.03,10.97,0,6.71,2.61,10.88,6.8,10.88,3.38,0,6.98-2.87,6.98-10.92,0-6.74-2.59-10.92-6.76-10.92Zm-.04,19.45c-3.37,0-3.87-5.34-3.87-8.52,0-2.55.37-8.48,3.78-8.48s3.74,5.93,3.74,8.48-.36,8.52-3.65,8.52Zm-.09-16.7c-3.14,0-3.48,5.72-3.48,8.18,0,3.07.46,8.22,3.57,8.22s3.35-5.75,3.35-8.22-.33-8.18-3.44-8.18Z"/>
      </g>
      <g>
        <path className="cls-1" d="m138.89,221.58c0-2.53-.13-3.81-.18-5.54h2.97l.18,2.57h.09c.93-1.73,2.66-2.92,4.92-2.92,2.97,0,5.18,2.21,5.18,7.4v14.4h-3.41v-13.82c0-2.53-.49-5.09-2.97-5.09-1.42,0-2.79,1.2-3.23,3.5-.09.53-.13,1.2-.13,1.9v13.51h-3.41v-15.9Z"/>
        <path className="cls-1" d="m152.2,237.63h-3.71v-13.97c0-3.37-.89-4.94-2.82-4.94-1.48,0-2.69,1.33-3.09,3.38-.08.51-.13,1.16-.13,1.88v13.66h-3.71v-16.05c0-1.6-.05-2.69-.1-3.74-.03-.59-.06-1.16-.07-1.79v-.15s3.26,0,3.26,0l.16,2.36c1.05-1.73,2.81-2.71,4.88-2.71,3.39,0,5.33,2.75,5.33,7.55v14.55Zm-3.41-.3h3.11v-14.25c0-4.61-1.83-7.25-5.03-7.25-2.07,0-3.81,1.04-4.79,2.84l-.04.08h-.32l-.18-2.57h-2.67c.02.57.04,1.09.07,1.63.05,1.05.1,2.14.1,3.75v15.75h3.11v-13.36c0-.73.05-1.4.14-1.93.42-2.2,1.75-3.62,3.38-3.62,2.1,0,3.12,1.72,3.12,5.24v13.67Z"/>
      </g>
      <g>
        <path className="cls-1" d="m160.91,233.94c.75.49,2.08,1.11,3.46,1.11,1.95,0,3.14-1.2,3.14-3.1,0-1.64-.58-2.75-2.75-4.21-2.79-1.82-4.3-3.63-4.3-6.11,0-3.46,2.57-5.94,5.89-5.94,1.68,0,2.97.58,3.85,1.11l-.93,2.57c-.8-.53-1.73-.93-2.83-.93-1.86,0-2.79,1.33-2.79,2.7,0,1.46.53,2.26,2.66,3.72,2.48,1.59,4.43,3.54,4.43,6.51,0,4.3-2.92,6.38-6.42,6.38-1.59,0-3.32-.49-4.25-1.24l.84-2.57Z"/>
        <path className="cls-1" d="m164.32,237.9c-1.66,0-3.41-.51-4.35-1.27l-.08-.06.93-2.85.16.11c.39.25,1.8,1.08,3.37,1.08,1.85,0,2.99-1.13,2.99-2.95,0-1.48-.43-2.57-2.68-4.08-3.06-1.99-4.36-3.86-4.36-6.24,0-3.47,2.6-6.09,6.04-6.09,1.38,0,2.66.37,3.93,1.13l.11.06-1.04,2.87-.16-.11c-.92-.61-1.82-.91-2.75-.91-1.81,0-2.64,1.32-2.64,2.55,0,1.43.56,2.2,2.59,3.6,2.09,1.34,4.5,3.32,4.5,6.63,0,3.97-2.58,6.53-6.57,6.53Zm-4.08-1.44c.91.69,2.53,1.14,4.08,1.14,3.03,0,6.27-1.64,6.27-6.23,0-2.48-1.34-4.45-4.36-6.38-2.14-1.47-2.73-2.3-2.73-3.85,0-1.37.92-2.85,2.94-2.85.94,0,1.85.28,2.76.85l.82-2.28c-1.18-.69-2.39-1.02-3.67-1.02-3.27,0-5.74,2.49-5.74,5.79,0,2.27,1.26,4.06,4.23,5.99,2.08,1.4,2.81,2.53,2.81,4.33,0,2-1.26,3.25-3.29,3.25-1.48,0-2.81-.69-3.37-1.03l-.75,2.29Z"/>
      </g>
      <g>
        <path className="cls-1" d="m191.78,231.64c0,2.39.13,4.25.18,5.85h-2.97l-.22-2.26h-.09c-.66,1.06-2.3,2.61-4.61,2.61-3.76,0-5.23-2.92-5.23-8.24v-13.55h3.41v13.07c0,3.37.53,5.85,2.88,5.85,1.99,0,2.88-1.86,3.06-2.83.13-.58.18-1.24.18-1.95v-14.13h3.41v15.59Z"/>
        <path className="cls-1" d="m184.07,237.99c-3.67,0-5.38-2.67-5.38-8.39v-13.71h3.71v13.22c0,3.99.82,5.7,2.73,5.7s2.74-1.77,2.91-2.71c.12-.51.17-1.14.17-1.92v-14.28h3.71v15.74c0,1.6.06,2.96.11,4.16.03.6.05,1.16.07,1.68v.15s-3.25,0-3.25,0l-.21-2.09c-.83,1.18-2.46,2.45-4.58,2.45Zm-5.08-21.79v13.4c0,5.52,1.61,8.09,5.08,8.09,2.13,0,3.75-1.37,4.48-2.54l.04-.07h.31l.22,2.26h2.68c-.01-.48-.04-.99-.06-1.53-.05-1.2-.11-2.57-.11-4.17v-15.44h-3.11v13.98c0,.81-.06,1.46-.18,1.98-.19,1.02-1.1,2.95-3.2,2.95-2.67,0-3.03-3.03-3.03-6v-12.92h-3.11Z"/>
      </g>
      <g>
        <path className="cls-1" d="m201.21,206.83h3.41v30.65h-3.41v-30.65Z"/>
        <path className="cls-1" d="m204.77,237.63h-3.71v-30.95h3.71v30.95Zm-3.41-.3h3.11v-30.35h-3.11v30.35Z"/>
      </g>
      <g>
        <path className="cls-1" d="m218.49,210.37v5.67h4.12v2.61h-4.12v12.71c0,2.79,1.02,3.54,2.26,3.54.53,0,.97-.04,1.37-.13l.13,2.61c-.62.22-1.42.35-2.53.35-1.33,0-2.52-.35-3.37-1.28-.84-.97-1.28-2.44-1.28-5.4v-12.4h-2.61v-2.61h2.61v-4.7l3.41-.97Z"/>
        <path className="cls-1" d="m219.73,237.9c-1.51,0-2.68-.45-3.48-1.33-.94-1.09-1.32-2.68-1.32-5.5v-12.25h-2.61v-2.91h2.61v-4.66l3.71-1.06v5.72h4.12v2.91h-4.12v12.56c0,3.04,1.2,3.39,2.11,3.39.51,0,.95-.04,1.34-.13l.17-.04.15,2.9-.1.04c-.68.24-1.53.36-2.58.36Zm-7.11-19.39h2.61v12.55c0,2.74.36,4.28,1.25,5.31.74.82,1.83,1.23,3.25,1.23.96,0,1.74-.1,2.37-.31l-.12-2.33c-.37.07-.77.1-1.23.1-1.62,0-2.41-1.21-2.41-3.69v-12.86h4.12v-2.31h-4.12v-5.62l-3.11.89v4.73h-2.61v2.31Z"/>
      </g>
      <g>
        <path className="cls-1" d="m243.07,226.76c0,8.28-3.85,11.07-7.13,11.07-3.85,0-6.95-3.5-6.95-11.03s3.5-11.12,7.18-11.12,6.91,3.59,6.91,11.07Zm-10.67,0c0,3.54.62,8.37,3.72,8.37,2.92,0,3.5-5.05,3.5-8.37s-.58-8.33-3.59-8.33-3.63,5.05-3.63,8.33Z"/>
        <path className="cls-1" d="m235.94,237.99c-3.43,0-7.1-2.94-7.1-11.18s3.78-11.27,7.33-11.27,7.06,2.95,7.06,11.22-3.92,11.22-7.28,11.22Zm.22-22.15c-3.4,0-7.03,2.88-7.03,10.97,0,6.71,2.61,10.88,6.8,10.88,3.38,0,6.98-2.87,6.98-10.92,0-6.74-2.59-10.92-6.76-10.92Zm-.04,19.45c-3.37,0-3.87-5.34-3.87-8.52,0-2.55.37-8.48,3.78-8.48s3.74,5.93,3.74,8.48-.36,8.52-3.65,8.52Zm-.09-16.7c-3.14,0-3.48,5.72-3.48,8.18,0,3.07.46,8.22,3.57,8.22s3.35-5.75,3.35-8.22-.33-8.18-3.44-8.18Z"/>
      </g>
      <g>
        <path className="cls-1" d="m251.48,221.58c0-1.82-.13-3.94-.18-5.54h3.06l.18,3.37h.09c.71-2.04,2.52-3.72,4.52-3.72.27,0,.49.04.71.04v3.5c-.22-.04-.44-.04-.71-.04-2.17,0-3.76,1.99-4.12,4.47-.09.58-.13,1.24-.13,1.95v11.87h-3.41v-15.9Z"/>
        <path className="cls-1" d="m255.04,237.63h-3.71v-16.05c0-1.17-.05-2.43-.11-3.66-.03-.67-.05-1.3-.07-1.87v-.15s3.35,0,3.35,0l.16,3.02c.86-1.98,2.69-3.37,4.48-3.37.14,0,.28.01.4.03.11,0,.21.02.31.02h.15v3.83l-.18-.04c-.21-.04-.42-.04-.68-.04-1.97,0-3.61,1.79-3.97,4.34-.09.57-.13,1.21-.13,1.93v12.02Zm-3.41-.3h3.11v-11.72c0-.73.05-1.39.13-1.97.39-2.71,2.14-4.6,4.27-4.6.2,0,.38,0,.56.02v-3.18c-.06,0-.12,0-.19-.02-.12-.01-.24-.02-.37-.02-1.81,0-3.65,1.52-4.38,3.62l-.04.1h-.34l-.18-3.37h-2.76c.02.53.04,1.11.07,1.71.05,1.23.11,2.5.11,3.67v15.75Z"/>
      </g>
      <g>
        <path className="cls-1" d="m270.84,210.51c.04,1.33-.8,2.21-2.08,2.21-1.11,0-1.95-.89-1.95-2.21s.89-2.21,1.99-2.21c1.24,0,2.04.89,2.04,2.21Zm-3.68,26.98v-21.44h3.41v21.44h-3.41Z"/>
        <path className="cls-1" d="m270.72,237.63h-3.71v-21.74h3.71v21.74Zm-3.41-.3h3.11v-21.14h-3.11v21.14Zm1.44-24.46c-1.22,0-2.1-1-2.1-2.37s.9-2.37,2.14-2.37c1.31,0,2.19.95,2.19,2.36.02.7-.18,1.3-.6,1.73-.4.42-.97.64-1.63.64Zm.04-4.43c-1.07,0-1.84.87-1.84,2.06s.74,2.06,1.8,2.06c.58,0,1.07-.19,1.42-.55.36-.37.54-.89.52-1.51h0c0-1.26-.74-2.07-1.89-2.07Z"/>
      </g>
      <g>
        <path className="cls-1" d="m288.42,237.48l-.22-2.26h-.13c-.93,1.64-2.44,2.61-4.25,2.61-2.88,0-5.09-2.44-5.09-6.11,0-5.36,4.3-7.75,9.12-7.8v-.66c0-2.88-.71-4.87-3.41-4.87-1.33,0-2.53.44-3.54,1.11l-.75-2.39c.89-.67,2.88-1.42,5-1.42,4.3,0,6.16,2.83,6.16,7.66v9.26c0,1.64,0,3.5.22,4.87h-3.1Zm-.49-11.07c-1.77,0-5.89.31-5.89,4.96,0,2.79,1.42,3.81,2.61,3.81,1.51,0,2.79-1.11,3.19-3.23.09-.4.09-.84.09-1.2v-4.34Z"/>
        <path className="cls-1" d="m283.82,237.99c-3.04,0-5.25-2.63-5.25-6.26,0-5.73,4.69-7.86,9.12-7.94v-.52c0-3.31-.97-4.72-3.26-4.72-1.2,0-2.36.36-3.46,1.08l-.17.11-.84-2.68.08-.06c.96-.72,3.02-1.45,5.09-1.45,4.19,0,6.31,2.63,6.31,7.81v9.26c0,1.63,0,3.49.22,4.85l.03.17h-3.41l-.21-2.13c-.99,1.6-2.49,2.49-4.26,2.49Zm.62-19.75c3.16,0,3.56,2.68,3.56,5.02v.81h-.15c-4.34.04-8.98,2.07-8.98,7.65,0,3.51,2.03,5.96,4.94,5.96,1.73,0,3.19-.9,4.12-2.54l.04-.08h.36l.22,2.26h2.79c-.2-1.36-.2-3.14-.2-4.72v-9.26c0-4.99-2.02-7.51-6.01-7.51-1.95,0-3.88.66-4.83,1.33l.66,2.1c1.1-.68,2.27-1.03,3.46-1.03Zm.22,17.09c-1.38,0-2.76-1.22-2.76-3.96,0-3.34,2.09-5.11,6.04-5.11h.15v4.49c0,.36,0,.81-.09,1.23-.39,2.07-1.67,3.35-3.33,3.35Zm3.13-8.77c-3.66.04-5.59,1.7-5.59,4.81,0,2.7,1.33,3.66,2.46,3.66,1.54,0,2.68-1.16,3.04-3.11.09-.39.09-.82.09-1.17v-4.19Z"/>
      </g>
      <g>
        <path className="cls-1" d="m314.73,227.07c.04,6.56,2.61,7.97,5.09,7.97,1.46,0,2.7-.35,3.54-.84l.53,2.53c-1.2.66-3.01,1.02-4.7,1.02-5.14,0-7.8-4.21-7.8-10.76,0-6.96,2.92-11.3,7.22-11.3s6.11,4.61,6.11,9.48c0,.8,0,1.37-.04,1.91h-9.97Zm6.73-2.53c.09-4.3-1.55-6.2-3.19-6.2-2.21,0-3.37,3.28-3.5,6.2h6.69Z"/>
        <path className="cls-1" d="m319.21,237.9c-5.05,0-7.95-3.98-7.95-10.91s2.89-11.45,7.37-11.45,6.26,4.97,6.26,9.63c0,.8,0,1.38-.04,1.92v.14h-9.96c.07,5.16,1.68,7.67,4.94,7.67,1.3,0,2.57-.3,3.47-.82l.18-.1.6,2.84-.1.05c-1.15.64-2.98,1.04-4.77,1.04Zm-.58-22.06c-4.29,0-7.07,4.38-7.07,11.15s2.79,10.61,7.65,10.61c1.69,0,3.41-.36,4.53-.95l-.47-2.22c-.94.49-2.15.76-3.44.76-3.44,0-5.21-2.73-5.24-8.12v-.15s9.98,0,9.98,0c.03-.49.03-1.03.03-1.75,0-4.51-1.57-9.33-5.96-9.33Zm2.98,8.86h-6.99v-.16c.15-3.06,1.35-6.34,3.66-6.34.66,0,1.28.28,1.8.82,1.04,1.06,1.59,3.03,1.54,5.54v.15Zm-6.68-.3h6.38c.03-2.36-.49-4.19-1.45-5.17-.46-.47-1.01-.73-1.59-.73-1.98,0-3.17,2.94-3.34,5.9Z"/>
      </g>
      <g>
        <path className="cls-1" d="m348.53,228.54l-1.99,8.95h-3.28l6.82-29.86h3.77l6.64,29.86h-3.37l-1.99-8.95h-6.6Zm6.16-2.75l-1.55-7.57c-.44-2.13-.84-4.61-1.2-6.82h-.18c-.35,2.26-.8,4.83-1.2,6.82l-1.55,7.57h5.67Z"/>
        <path className="cls-1" d="m360.68,237.63h-3.67l-1.99-8.95h-6.36l-1.99,8.95h-3.59l6.89-30.16h4l6.71,30.16Zm-3.43-.3h3.06l-6.58-29.55h-3.53l-6.75,29.55h2.97l1.99-8.95h6.84l1.99,8.95Zm-2.37-11.39h-6.04l1.59-7.75c.37-1.86.81-4.34,1.19-6.82l.02-.13h.43l.02.13c.33,2.08.75,4.66,1.19,6.82l1.59,7.75Zm-5.67-.3h5.3l-1.51-7.39c-.42-2.03-.82-4.44-1.14-6.45-.37,2.35-.78,4.68-1.14,6.45l-1.51,7.39Z"/>
      </g>
      <g>
        <path className="cls-1" d="m368.46,221.58c0-2.53-.13-3.81-.18-5.54h2.97l.18,2.57h.09c.93-1.73,2.66-2.92,4.92-2.92,2.97,0,5.18,2.21,5.18,7.4v14.4h-3.41v-13.82c0-2.53-.49-5.09-2.97-5.09-1.42,0-2.79,1.2-3.23,3.5-.09.53-.13,1.2-.13,1.9v13.51h-3.41v-15.9Z"/>
        <path className="cls-1" d="m381.77,237.63h-3.71v-13.97c0-3.37-.89-4.94-2.82-4.94-1.48,0-2.69,1.33-3.09,3.38-.08.51-.13,1.16-.13,1.88v13.66h-3.71v-16.05c0-1.6-.05-2.69-.1-3.74-.03-.59-.06-1.16-.07-1.79v-.15s3.26,0,3.26,0l.16,2.36c1.05-1.73,2.81-2.71,4.88-2.71,3.39,0,5.33,2.75,5.33,7.55v14.55Zm-3.41-.3h3.11v-14.25c0-4.61-1.83-7.25-5.03-7.25-2.07,0-3.81,1.04-4.79,2.84l-.04.08h-.32l-.18-2.57h-2.67c.02.57.04,1.09.07,1.63.05,1.05.1,2.14.1,3.75v15.75h3.11v-13.36c0-.73.05-1.4.14-1.93.42-2.2,1.75-3.62,3.38-3.62,2.1,0,3.12,1.72,3.12,5.24v13.67Z"/>
      </g>
      <g>
        <path className="cls-1" d="m399.51,237.48l-.22-2.26h-.13c-.93,1.64-2.44,2.61-4.25,2.61-2.88,0-5.09-2.44-5.09-6.11,0-5.36,4.3-7.75,9.12-7.8v-.66c0-2.88-.71-4.87-3.41-4.87-1.33,0-2.53.44-3.54,1.11l-.75-2.39c.89-.67,2.88-1.42,5-1.42,4.3,0,6.16,2.83,6.16,7.66v9.26c0,1.64,0,3.5.22,4.87h-3.1Zm-.49-11.07c-1.77,0-5.89.31-5.89,4.96,0,2.79,1.42,3.81,2.61,3.81,1.51,0,2.79-1.11,3.19-3.23.09-.4.09-.84.09-1.2v-4.34Z"/>
        <path className="cls-1" d="m394.9,237.99c-3.04,0-5.25-2.63-5.25-6.26,0-5.73,4.69-7.86,9.12-7.94v-.52c0-3.31-.97-4.72-3.26-4.72-1.2,0-2.36.36-3.46,1.08l-.17.11-.84-2.68.08-.06c.96-.72,3.02-1.45,5.09-1.45,4.19,0,6.31,2.63,6.31,7.81v9.26c0,1.63,0,3.49.22,4.85l.03.17h-3.41l-.21-2.13c-.99,1.6-2.49,2.49-4.26,2.49Zm.62-19.75c3.16,0,3.56,2.68,3.56,5.02v.81h-.15c-4.34.04-8.98,2.07-8.98,7.65,0,3.51,2.03,5.96,4.94,5.96,1.73,0,3.19-.9,4.12-2.54l.04-.08h.36l.22,2.26h2.79c-.2-1.36-.2-3.14-.2-4.72v-9.26c0-4.99-2.02-7.51-6.01-7.51-1.95,0-3.88.66-4.83,1.33l.66,2.1c1.1-.68,2.27-1.03,3.46-1.03Zm.22,17.09c-1.38,0-2.76-1.22-2.76-3.96,0-3.34,2.09-5.11,6.04-5.11h.15v4.49c0,.36,0,.81-.09,1.23-.39,2.07-1.67,3.35-3.34,3.35Zm3.13-8.77c-3.66.04-5.59,1.7-5.59,4.81,0,2.7,1.33,3.66,2.46,3.66,1.54,0,2.68-1.16,3.04-3.11.09-.39.09-.82.09-1.17v-4.19Z"/>
      </g>
      <g>
        <path className="cls-1" d="m411.82,206.83h3.41v30.65h-3.41v-30.65Z"/>
        <path className="cls-1" d="m415.38,237.63h-3.71v-30.95h3.71v30.95Zm-3.41-.3h3.11v-30.35h-3.11v30.35Z"/>
      </g>
      <g>
        <path className="cls-1" d="m426.7,216.04l2.83,12.31c.4,1.68.62,3.1.89,4.43h.09c.22-1.2.58-2.83.93-4.39l2.53-12.36h3.37l-3.5,13.78c-1.33,5.4-2.79,10.59-5.32,13.82-1.51,1.9-3.1,2.92-3.85,3.28l-1.24-2.92c.97-.53,2.04-1.46,3.01-2.7.89-1.11,1.59-2.57,1.95-3.59.18-.53.22-.75.22-1.06,0-.27,0-.53-.09-.8l-5.4-19.8h3.59Z"/>
        <path className="cls-1" d="m424.59,247.12l-1.35-3.19.12-.07c.99-.54,2.04-1.48,2.97-2.66.91-1.13,1.6-2.62,1.92-3.54.17-.51.21-.72.21-1.01,0-.26,0-.51-.08-.75l-5.46-20h3.9l.03.12,2.83,12.31c.27,1.15.46,2.15.64,3.12.05.26.1.52.15.78.2-1.02.49-2.37.83-3.86l2.55-12.48h3.68l-.05.19-3.5,13.78c-1.28,5.19-2.74,10.54-5.34,13.88-1.78,2.25-3.57,3.16-3.91,3.32l-.14.07Zm-.97-3.06l1.13,2.65c.54-.28,2.1-1.19,3.66-3.17,2.56-3.28,4.02-8.6,5.29-13.76l3.45-13.59h-3.05l-2.5,12.24c-.4,1.76-.73,3.32-.93,4.38l-.02.12h-.34l-.02-.12c-.08-.43-.17-.86-.25-1.32-.18-.97-.36-1.97-.63-3.11l-2.81-12.2h-3.27l5.35,19.61c.09.28.09.56.09.84,0,.33-.05.57-.23,1.11-.33.95-1.04,2.47-1.97,3.63-.91,1.16-1.95,2.11-2.94,2.67Z"/>
      </g>
      <g>
        <path className="cls-1" d="m449.07,210.37v5.67h4.12v2.61h-4.12v12.71c0,2.79,1.02,3.54,2.26,3.54.53,0,.97-.04,1.37-.13l.13,2.61c-.62.22-1.42.35-2.53.35-1.33,0-2.52-.35-3.37-1.28-.84-.97-1.28-2.44-1.28-5.4v-12.4h-2.61v-2.61h2.61v-4.7l3.41-.97Z"/>
        <path className="cls-1" d="m450.31,237.9c-1.51,0-2.68-.45-3.48-1.33-.94-1.09-1.32-2.68-1.32-5.5v-12.25h-2.61v-2.91h2.61v-4.66l3.71-1.06v5.72h4.12v2.91h-4.12v12.56c0,3.04,1.2,3.39,2.11,3.39.51,0,.95-.04,1.34-.13l.17-.04.15,2.9-.1.04c-.68.24-1.53.36-2.58.36Zm-7.11-19.39h2.61v12.55c0,2.74.36,4.28,1.25,5.31.74.82,1.83,1.23,3.25,1.23.96,0,1.74-.1,2.37-.31l-.12-2.33c-.37.07-.77.1-1.23.1-1.62,0-2.41-1.21-2.41-3.69v-12.86h4.12v-2.31h-4.12v-5.62l-3.11.89v4.73h-2.61v2.31Z"/>
      </g>
      <g>
        <path className="cls-1" d="m464.53,210.51c.04,1.33-.8,2.21-2.08,2.21-1.11,0-1.95-.89-1.95-2.21s.89-2.21,1.99-2.21c1.24,0,2.04.89,2.04,2.21Zm-3.68,26.98v-21.44h3.41v21.44h-3.41Z"/>
        <path className="cls-1" d="m464.41,237.63h-3.71v-21.74h3.71v21.74Zm-3.41-.3h3.11v-21.14h-3.11v21.14Zm1.44-24.46c-1.22,0-2.1-1-2.1-2.37s.9-2.37,2.14-2.37c1.31,0,2.19.95,2.19,2.36.02.7-.18,1.3-.6,1.73-.4.42-.97.64-1.63.64Zm.04-4.43c-1.07,0-1.84.87-1.84,2.06s.74,2.06,1.8,2.06c.58,0,1.07-.19,1.42-.55.36-.37.54-.89.52-1.51h0c0-1.26-.74-2.07-1.89-2.07Z"/>
      </g>
      <g>
        <path className="cls-1" d="m484.11,236.95c-.75.44-2.04.8-3.54.8-4.7,0-7.84-3.76-7.84-10.85,0-6.16,3.1-11.16,8.46-11.16,1.15,0,2.39.31,3.01.66l-.66,2.79c-.44-.22-1.33-.58-2.44-.58-3.41,0-4.96,4.08-4.96,8.28,0,5.01,1.9,8.02,5.05,8.02.93,0,1.68-.22,2.48-.58l.44,2.61Z"/>
        <path className="cls-1" d="m480.56,237.9c-5,0-7.99-4.11-7.99-11s3.54-11.31,8.61-11.31c1.1,0,2.39.29,3.09.68l.1.06-.73,3.08-.17-.08c-.42-.21-1.28-.56-2.37-.56-3.32,0-4.81,4.08-4.81,8.13,0,4.93,1.83,7.87,4.9,7.87.81,0,1.53-.17,2.42-.56l.18-.08.49,2.91-.09.05c-.87.51-2.22.82-3.62.82Zm.62-22.01c-4.97,0-8.31,4.42-8.31,11.01s2.87,10.7,7.69,10.7c1.3,0,2.55-.27,3.38-.72l-.39-2.32c-.85.36-1.57.51-2.37.51-3.25,0-5.2-3.05-5.2-8.17,0-4.2,1.58-8.43,5.11-8.43,1.02,0,1.85.29,2.33.51l.59-2.5c-.59-.3-1.72-.59-2.84-.59Z"/>
      </g>
      <g>
        <path className="cls-1" d="m491.1,233.94c.75.49,2.08,1.11,3.46,1.11,1.95,0,3.14-1.2,3.14-3.1,0-1.64-.58-2.75-2.75-4.21-2.79-1.82-4.3-3.63-4.3-6.11,0-3.46,2.57-5.94,5.89-5.94,1.68,0,2.97.58,3.85,1.11l-.93,2.57c-.8-.53-1.73-.93-2.83-.93-1.86,0-2.79,1.33-2.79,2.7,0,1.46.53,2.26,2.66,3.72,2.48,1.59,4.43,3.54,4.43,6.51,0,4.3-2.92,6.38-6.42,6.38-1.59,0-3.32-.49-4.25-1.24l.84-2.57Z"/>
        <path className="cls-1" d="m494.51,237.9c-1.66,0-3.41-.51-4.35-1.27l-.08-.06.93-2.85.16.11c.39.25,1.8,1.08,3.37,1.08,1.85,0,2.99-1.13,2.99-2.95,0-1.48-.43-2.57-2.68-4.08-3.06-1.99-4.36-3.86-4.36-6.24,0-3.47,2.6-6.09,6.04-6.09,1.38,0,2.66.37,3.93,1.13l.11.06-1.04,2.87-.16-.11c-.92-.61-1.82-.91-2.75-.91-1.81,0-2.64,1.32-2.64,2.55,0,1.43.56,2.2,2.59,3.6,2.09,1.34,4.5,3.32,4.5,6.63,0,3.97-2.58,6.53-6.57,6.53Zm-4.08-1.44c.91.69,2.53,1.14,4.08,1.14,3.03,0,6.27-1.64,6.27-6.23,0-2.48-1.34-4.45-4.36-6.38-2.14-1.47-2.73-2.3-2.73-3.85,0-1.37.92-2.85,2.94-2.85.94,0,1.85.28,2.76.85l.82-2.28c-1.18-.69-2.39-1.02-3.67-1.02-3.27,0-5.74,2.49-5.74,5.79,0,2.27,1.26,4.06,4.23,5.99,2.08,1.4,2.81,2.53,2.81,4.33,0,2-1.26,3.25-3.29,3.25-1.48,0-2.81-.69-3.37-1.03l-.75,2.29Z"/>
      </g>
    </g>
  </Container>
  );
};
