import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 54px 40px;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
 
  h1 {
    margin-bottom: 20px;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  .button_wrapper {
    margin-top: 48px;
    display: flex;
    justify-content: space-evenly;
  }

  .cancel_button {
    padding: 8px 14px;
    justify-content: center;
    align-items: flex-start;
    background: #fff;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      color: #a10e25;
    }
  }

  .confirm_button {
    padding: 8px 14px;
    justify-content: center;
    align-items: flex-start;
    background: #004F9F;
    border-radius: 8px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      color: #FFFFFF;
    }
  }
`;
