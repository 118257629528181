import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@100;300;400;500;700&display=swap');
:root {
    --font-main: 'Calibri', 'Open Sans', Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    --max-width: 1920px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-main);
  }

  body {
    max-width: 100vw;
    overflow-x: hidden;
  }


  .restrict_action_light_button {
    background: hsla(33, 93%, 58%, 1);
    background: linear-gradient(180deg, hsla(33, 93%, 58%, 1) 0%, hsla(43, 99%, 58%, 1) 50%, hsla(48, 100%, 57%, 1) 100%);
    background: -moz-linear-gradient(180deg, hsla(33, 93%, 58%, 1) 0%, hsla(43, 99%, 58%, 1) 50%, hsla(48, 100%, 57%, 1) 100%);
    background: -webkit-linear-gradient(180deg, hsla(33, 93%, 58%, 1) 0%, hsla(43, 99%, 58%, 1) 50%, hsla(48, 100%, 57%, 1) 100%);
  }

  .restrict_action_dark_button {
    background: #000000;
  }

  button {
    cursor: pointer;
    border: none;
  }
`;
