import styled from "styled-components";

export const GlobalWrapperContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

interface IProps {
  open: boolean;
}

export const GlobalContainer = styled.main<IProps>`
  width: 100%;
  display: flex;
  min-height: 100vh;
  max-width: var(--max-width);

  .content_wrapper {
    width: ${({ open }) => open ? '90%' : '100%'};

    @media (max-width: 960px) {
      width: 100%;
    }
  }
`;

interface IHeaderProps {
  mobile: boolean;
}

export const Header = styled.header<IHeaderProps>`
  gap: 72px;
  width: 100%;
  color: white;
  height: 63px;
  display: flex;
  padding: 20px 40px;
  align-items: center;
  background: #F18732;
  justify-content: ${({ mobile }) => (mobile ? "center" : "space-between")};

  button {
    border-radius: 23px;
    background: #fff;
    padding: 8px 12px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #205ca8;
    }
  }

  .user_wrapper {
    gap: 8px;
    display: flex;

    .icon {
      width: 20px;
      height: 20px;
      background: red;
    }
  }

  .municipaismo_icon {
    transform: scale(0.7);
  }

  .action_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
  }
`;
