import React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  onClick: () => void;
}

export const MenuFilled: React.FC<IProps> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill={theme.header.logo_header_background} />
    </svg>
  );
};
