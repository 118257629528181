import React from "react";
import Head from "next/head";
import { useAuth } from "@/hooks/useAuth";
import GlobalStyles from "@/styles/global";
import { dark } from "@/styles/themes/dark";
import { light } from "@/styles/themes/light";
import { useSidebarMenu } from "@/hooks/useMenu";
import { ThemeProvider } from "styled-components";
import { useTheme } from "@/services/zustand/theme";
import { PrivateSideBar } from "../../SideBar/Private";
import { MenuFilled } from "../../Header/svgs/MenuFill";
import { useMediaQuery, query } from "@/hooks/useMediaQuery";
import { PersonFilled } from "../../Header/svgs/PersonFilled";
import { MunicipalismoIcon } from "../../Header/svgs/LogoMunicipalismo";
import { GlobalContainer, GlobalWrapperContainer, Header } from "./styles";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { PasswordModal } from "./Modal";
import { FrameMotionTransition } from "../../FramerMotion";

interface IProps {
  children: React.ReactNode;
}

export const PrivateLayout: React.FC<IProps> = ({ children }) => {
  const [isClient, setIsClient] = React.useState(false);
  const theme = useTheme((state) => state.theme);
  const { isMobile } = useMediaQuery(query);
  const router = useRouter();
  const pathname = usePathname();
  const { setOPenPrivateMenu, openPrivateMenu } = useSidebarMenu();
  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => setIsClient(true), []);

  React.useEffect(() => {
    // Verifique a validade do token aqui (pode usar a biblioteca jsonwebtoken)
    // Certifique-se de validar a data de expiração do token
    const token = localStorage.getItem("JWT_USER");
    if (!token) {
      // Token não encontrado, redirecione para a página de login
      router.push("/login");
      return;
    }

    try {
      // Verifique o token JWT e obtenha os dados, incluindo a data de expiração
      const token = localStorage.getItem('EXPIRATION');
      const decodedToken =  Number(token)
      
      if (Date.now() > decodedToken) {
        // Token expirado, redirecione para a página de login
        router.push("/login");
      }
    } catch (error) {
      // Token inválido, redirecione para a página de login
      router.push("/login");
    }
  }, [pathname, router]);

  React.useEffect(() => {
    const modules = JSON.parse(localStorage.getItem("USER_GROUP") as string);
    if (modules?.some((item: any) => item.code === 'users') === false) {
      if (pathname === '/dashboard/novo_usuario' || pathname === '/dashboard/confirmar_cadastro') {
        router.push('/');
      }
    }
  }, [pathname, router]);

  React.useEffect(() => {
    if (!localStorage.getItem("JWT_USER")) {
      router.push('/login');
    }
  }, [router]);

  return (
    <>
      {isClient && (
        <ThemeProvider theme={theme === "light" ? light : dark}>
          <Head>
            <title>Macroplan SACP</title>
            <meta name="description" content="criado por macroplan" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href="/favicon.ico" />
          </Head>
          <FrameMotionTransition>
            <GlobalStyles />
            <GlobalWrapperContainer>
              <GlobalContainer open={openPrivateMenu}>
                <PrivateSideBar />
                <div className="content_wrapper">
                  <Header mobile={isMobile}>
                    {!isMobile && (
                      <>
                    <div>
                      <MenuFilled
                        onClick={() => setOPenPrivateMenu(!openPrivateMenu)}
                      />
                    </div>
                    <div className='action_wrapper'>
                        <button onClick={() => setOpenModal(!openModal)}>
                          <p>Mudar senha</p>
                        </button>
                        <div className="user_wrapper">
                          {/* <p>{JSON.parse(localStorage.getItem("USER_NAME") as string)}</p> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              d="M10.0007 10.5C11.8423 10.5 13.334 9.00837 13.334 7.16671C13.334 5.32504 11.8423 3.83337 10.0007 3.83337C8.15898 3.83337 6.66732 5.32504 6.66732 7.16671C6.66732 9.00837 8.15898 10.5 10.0007 10.5ZM10.0007 12.1667C7.77565 12.1667 3.33398 13.2834 3.33398 15.5V17.1667H16.6673V15.5C16.6673 13.2834 12.2257 12.1667 10.0007 12.1667Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                    </div>
                      </>
                    )}
                    {isMobile && (
                      <>
                        <MenuFilled
                          onClick={() => setOPenPrivateMenu(!openPrivateMenu)}
                        />
                        <PersonFilled />
                      </>
                    )}
                  </Header>
                  {children}
                </div>
                <PasswordModal isOpen={openModal} onClose={() => setOpenModal(!openModal)} />
              </GlobalContainer>
            </GlobalWrapperContainer>

          </FrameMotionTransition>
        </ThemeProvider>
      )}
    </>
  );
};
