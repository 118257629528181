export const dark = {
  header: {
    navigation_span: "#000000",
    header_background: "#FFFFFF",
    logo_header_background: "#000000",
  },
  home: {
    banner: {
      banner_background: "#1D1D1B",
      accession_button: "#004F9F",
      color: "#FFF",
    },
    objectives: {
      objectives_background: "#1D1D1D",
      description: "#FFF",
      text_content: "#FFF",
    },
    action_area: {
      action_area_background: "#1D1D1D",
      title_area: "#FFF",
    },
    approuch: {
      background: "#1D1D1D",
      text_content: "#FFF",
      new_approuch: "#E6E7E8",
    },
  },
  participe: {
    banner: {
      background: "#1D1D1D",
      text: "#FFF",
    },
    form: {
      background: "#1D1D1D",
    },
  },
  perguntas: {
    accordeon: {
      background: "#1D1D1D",
    },
  },
  acontece: {
    load_more: {
      main_color: "#FFFFFF",
      icon_load: "#FFFFFF",
    },
    card: {
      main_color: "#1D1D1B",
    },
  },
  acontece_interno: {
    background: "#1D1D1D",
    secundary_text: "#FFF",
    primary_text: "#FFF",
  },
  login:{
    button:{
      bg: '#1D1D1B',
    }
  }
};
