import React from "react";
import { useRouter } from "next/router";
import { Container, Header, Nav, Exit, CircleFluid } from "./styles";
import { useMediaQuery, query } from "@/hooks/useMediaQuery";
import { useSidebarMenu } from "@/hooks/useMenu";
import { MunicipalismoIcon } from "../../Header/svgs/LogoMunicipalismo";
import { AconteceSidebarIcon } from "./svgs/acontece";
import { CadastroIcon } from "./svgs/cadastro";
import { SairIcon } from "./svgs/sair";
import { CloseIcon } from "./svgs/close";
import { UsuariosIcon } from "./svgs/usuarios";
import { useAuth } from "@/hooks/useAuth";
import Image from "next/image";
import HelpDesk from "./svgs/helpDesk";
import { useTimes } from "@/hooks/useTime";

export function PrivateSideBar() {
  const router = useRouter();
  const { openPrivateMenu, setOPenPrivateMenu } = useSidebarMenu();
  const { isMobile } = useMediaQuery(query);
  const { handleLogout } = useAuth();
  const { disabledButton, setDisabledButton } = useTimes();

  const modules = JSON.parse(localStorage.getItem("USER_GROUP") as string);

  const routerPush = (route: string) => {
    if (disabledButton) {
      const confirmationMessage = "Deseja sair sem salvar as alterações?";
      const userResponse = window.confirm(confirmationMessage);
      if (userResponse) {
        setDisabledButton(false);
        router.push(route);
      }
    } else {
      router.push(route);
    }
  };  

  return (
    <Container open={openPrivateMenu} mobile={isMobile}>
      <div className='close_wrapper'>
        <CloseIcon onClick={() => setOPenPrivateMenu(!openPrivateMenu)} />
      </div>
      <div className="content_wrapper">
        <Header>
          <MunicipalismoIcon />

        </Header>
        <Nav>
          <div className="sub_item_wrapper">
            <div className="content">
              <AconteceSidebarIcon />
              <p>Lançamentos</p>
            </div>
            <div className="divider" />
            <div
              className="sub_item"
              onClick={() => {
                routerPush("/");
              }}
            >
              <p>Horas</p>
            </div>
            <div
              className="sub_item"
              onClick={() => {
                routerPush("/dashboard/relatorios");
              }}
            >
              <p>Relatórios</p>
            </div>
            <div
              className="sub_item"
              onClick={() => {
                routerPush("/dashboard/meus_projetos");
              }}
            >
              <p>Meus Projetos</p>
            </div>
          </div>
         

          {modules?.some((module: any) => module.code === "users") && (
            <>

              <div className="sub_item_wrapper">
                <div className="content">
                  <AconteceSidebarIcon />
                  <p>Cadastros</p>
                </div>
                <div className="divider" />
                <div
                  className="sub_item"
                  onClick={() => {
                    routerPush("/dashboard/projetos");
                  }}
                >
                  <p>Projetos</p>
                </div>
                <div
                  className="sub_item"
                  onClick={() => {
                    routerPush("/dashboard/tipo");
                  }}
                >
                  <p>Tipos</p>
                </div>
                <div
                  className="sub_item"
                  onClick={() => {
                    routerPush("/dashboard/action");
                  }}
                >
                  <p>Ações</p>
                </div>

              </div>
              {/* <div
                className="item_wrapper"
                onClick={() => {
                  routerPush("/dashboard/confirmar_cadastro");
                }}
              >
                <CadastroIcon />
                <p>Confirmar cadastros</p>
              </div> */}
              <div className="sub_item_wrapper">
                <div className="content">
                  <UsuariosIcon />
                  <p>Usuários</p>
                </div>
                <div className="divider" />
                <div
                  className="sub_item"
                  onClick={() => {
                    routerPush("/dashboard/novo_usuario");
                  }}
                >
                  <p>Cadastrar novo usuário</p>
                </div>
                <div
                  className="sub_item"
                  onClick={() => {
                    routerPush("/dashboard/bloqueio");
                  }}
                >
                  <p>Bloqueio</p>
                </div>
                {/* <div
                  className="sub_item"
                  onClick={() => {
                    routerPush("/dashboard/novo_grupo");
                  }}
                >
                  <p>Novo grupo de usuarios</p>
                </div> */}


              </div>
            </>
          )}
          <div className="sub_item_wrapper">
            <div className="content">
              <HelpDesk />
              <p>Ajuda</p>
            </div>
            <div className="divider" />
            <div
              className="sub_item"
              onClick={() => {
                window.open("https://servicedesk.macrodevplan.kinghost.net/", "_blank");
              }}
            >
              <p>HelpDesk</p>
            </div>
          
          </div>
        </Nav>
        <Exit>
          <div
            className="item_wrapper"
            onClick={() => handleLogout()}
          >
            <SairIcon />
            <p style={{ cursor: 'pointer' }}>Sair</p>
          </div>
        </Exit>
      </div>
    </Container>
  );
}
